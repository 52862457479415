import './App.css';
import Dnevnik from './Modules/Dnevnik'
import './adaptation.css'
function AppDnevnik() {
  return (
    <>
      <div className="wrapper mt20">
         <Dnevnik />
      </div>
    </>
  );
}

export default AppDnevnik;
